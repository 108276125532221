import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import * as styles from '../components/globals-v2.module.css'
import * as start from '../pages/content-hub/start.module.css'
import Category from '../components/hub/category/category'
import Card from '../components/hub/card/card'
import * as linkStyle from '../styles/components/links.module.css'
import Search from '../components/hub/search/search'

export const query = graphql`
    query TagTemplateQuery($slug: String!) {
        tag: sanityTag(slug: {current: {eq: $slug}}) {
            title
            slug {
                current
            }
        }

        audio: allSanityAudio(filter: {tags: {elemMatch: {slug: {current: {eq: $slug}}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _id
                _type
                tags {
                title
                slug {
                    current
                }
                }
                title
                slug {
                current
                }
                series {
                    title
                    slug {
                        current
                    }
                }
                featureImage {
                asset {
                    _id
                    url
                }
                }
                appleUrl
                spotifyUrl
                publishedAt
            }
        }

        video: allSanityVideo(filter: {tags: {elemMatch: {slug: {current: {eq: $slug}}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _id
                _type
                tags {
                title
                slug {
                    current
                }
                }
                title
                slug {
                current
                }
                series {
                    title
                    slug {
                        current
                    }
                }
                featureImage {
                asset {
                    _id
                    url
                }
                }
                publishedAt
            }
        }

        written: allSanityWritten(filter: {tags: {elemMatch: {slug: {current: {eq: $slug}}}}}, sort: {order: DESC, fields: publishedAt}) {
            nodes {
                _id
                _type
                tags {
                    title
                    slug {
                        current
                    }
                }
                openLink
                title
                blurb
                slug {
                    current
                }
                featureImage {
                    asset {
                        url
                        _id
                    }
                }
                publishedAt
            }
        }
        tags: allSanityTag(filter: { display: { ne: false } }) {
            nodes {
                display
                title
                position
                slug {
                    current
                }
            }
        }
    }
`

const TagTemplate = props => {
  const { data } = props
  const tag = data.tag
  const writtenArticles = data.written.nodes
  const audioPosts = data.audio.nodes
  const videoPosts = data.video.nodes
  const perPage = 4

  const [allPosts, setAllPosts] = useState(null)
  const [filteredPosts, setFilteredPosts] = useState(null)
  const [mostRecentCount, setMostRecentCount] = useState(perPage)

  useEffect(() => {
    function getMostRecentPosts (writtenArticles, audioPosts, videoPosts) {
      const allPosts = [writtenArticles, audioPosts, videoPosts]
        .filter(Boolean)
        .flat()
        .filter(node =>
          !node._id.startsWith('drafts.') &&
          !['cartoon-avatars', 'web3-after-dark'].includes(node.series?.slug?.current) // TODO: delete this after the cartoon avatars are removed from sanity
        )

      if (allPosts.length === 0) {
        return null
      }

      allPosts.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))

      setAllPosts(allPosts)
      return allPosts
    }

    const recentPosts = getMostRecentPosts(writtenArticles, audioPosts, videoPosts)
    if (!recentPosts) return

    setFilteredPosts(recentPosts.slice(0, mostRecentCount))
  }, [mostRecentCount, writtenArticles, audioPosts, videoPosts])

  return (
    <Layout hideContactBar hideAnnouncements logoOverrideUrl='/content-hub/' version='1'>

      <SEO title={`Category: ${tag.title}`} />

      <Container size='large' class={styles.container}>
        <section className={start.menuWtSearch}>
          <Category nodes={data.tags.nodes} type='category' current={tag.slug.current} />
          <Search />
        </section>

        <section className={styles.tagHead}>
          <Link to='/content-hub/' className={`${linkStyle.linkArrow} ${styles.linkArrow} ${linkStyle.back}`}>
            Back
          </Link>
          <div className={styles.tags}>
            <span className={styles.tag} style={{ height: 'auto' }}>
              {tag.title}
            </span>
          </div>
        </section>

        {filteredPosts !== null ? (
          <section className={`${start.twoColumnsGrid} ${start.separator} ${start.tag}`}>
            <div className={`${start.sectionWrapper} ${start.recentWrapper}`}>
              {filteredPosts.map((article, index) => (
                <Card key={index} section='mostRecent' type='simple' data={article} />
              ))}
            </div>
            {(mostRecentCount < allPosts?.length) && (
              <div className={start.moreButton}>
                <a className={linkStyle.linkPlus} onClick={() => setMostRecentCount(mostRecentCount + perPage)}>More
                  <span>
                    <div className={linkStyle.iconPlus} />
                  </span>
                </a>
              </div>
            )}
          </section>
        ) : <p className={styles.noResults}>No results found</p>}
      </Container>
    </Layout>
  )
}

export default TagTemplate
